<template>
<div class="receiving-qr-detail">
    <div class="receiving-qr-detail-header">
        <div class="header-back">
            <Svgs @click.native="backCall" color="#ffffff" name="iconfanhui"></Svgs>
        </div>
        <span class="header-title">{{itemData.title}}</span><br>
        <span class="header-sub-title">MY PAYMENT CODE</span>
    </div>
    <div class="receiving-qr-detail-content">
        <div class="content-box">
            <p class="content-box-title">{{itemData.content}}</p>
            <img :src="itemData.url" alt="" srcset="">
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ReceivingQRDetail',
    components: {},
    data() {
        return {
            itemData: {}
        }
    },
    created() {
        this.itemData = this.$route.params.itemInfo
    },
    methods: {
        backCall() {
            // this.$router.push('customerService')
            this.$router.back()
        }
    }
}
</script>

<style lang="scss">
.receiving-qr-detail {
    background: var(--themeColor);
    //background-image: linear-gradient(var(--themeColor), var(--gradualColor));
    height: 100vh;
    .receiving-qr-detail-content {
        padding: .2rem .26rem .26rem .26rem;
        .content-box {
            min-height: 4rem;
            background: rgba(255, 255, 255, 1);
            border-radius: .13rem;
            padding: .3rem 0;
            text-align: center;
            .content-box-title {
                width: 100%;
                height: .46rem;
                // background: rgba(250, 250, 250, 1);
                text-align: center;
                font-size: .12rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: var(--themeColor);
                // line-height: .46rem;
            }
            img {
                padding-top: .4rem;
                width: 2.7rem;
                border-radius: .1rem;
            }
        }
    }
    .receiving-qr-detail-header {
        padding: 0 .25rem;
        // position: absolute;
        left: 0;
        top: 0;
        // width: 100%;
        z-index: 100;
        .header-back {
            text-align: left;
            padding: .15rem 0;
        }
        .header-title {
            color: rgba(255, 255, 255, 1);
            font-size: .24rem;
            text-align: left;
        }
        .header-sub-title {
            font-size: .1rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            line-height: .14rem;
        }
    }
}
</style>